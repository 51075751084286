import * as React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "../../../common/use-translations/use-translations"
import { CareerNav } from "../career-nav/career-nav"
import { SuccessStoriesList } from "./success-stories-list"

export  function SuccessStoriesPage() {
  const _ = useTranslation();
  return (
    <>
      <Helmet>
        <title>{_.success_stories} - OBRIO</title>
      </Helmet>
      <CareerNav />
      <SuccessStoriesList />
    </>
  )
}
