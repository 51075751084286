import * as React from "react"
import * as css from "./success-story-screen.module.scss"
import { Icon } from "../../../../common/icon/icon"
import { SuccessStory } from "../success-story"
import classNames from "classnames"
import { useTranslation } from "../../../../common/use-translations/use-translations"

export const SuccessStoryScreen = (props: {
  story: SuccessStory
  reverse: boolean
}) => {
  const _ = useTranslation();
  const reverseClass = props.reverse ? css.reverse : ""
  return (
    <section className={css.section}>
      <div className="container">
        <div className={classNames(css.grid, reverseClass)}>
          <div className={css.image}>
            <img src={props.story.image} alt="" />
          </div>
          <div className={css.text}>
            <h2 className={css.title}>{_[props.story.name]}</h2>
            <div className={css.position}>{props.story.position}</div>
            <div className={css.quote}>
              <Icon name="quote" />
            </div>
            <p
              className={css.description}
              dangerouslySetInnerHTML={{ __html: _[props.story.description] }}
            >
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
