import { SuccessStory } from "./success-story"

export const SuccessStoriesInfoList: SuccessStory[] = [
  {
    name: "serhii_matveev",
    position: "COO",
    image: "/success-story/matveev.png",
    description:
      "serhii_matveev_story",
  },
  {
    name: "maks_lukominskii",
    position: "CMO",
    image: "/success-story/lukominskyi.png",
    description:
      "maks_lukominskii_story",
  },
  {
    name: "oleksii_rumiantsev",
    position: "Lead Back-End Engineer",
    image: "/success-story/rumiantsev.png",
    description:
      "oleksii_rumiantsev_story",
  },
  {
    name: "anton_vodolazky",
    position: "CTO",
    image: "/success-story/vodolazky.png",
    description:
      "anton_vodolazky_story",
  },
  {
    name: "dmytro_avramenko",
    position: "Product Analyst",
    image: "/success-story/avram.png",
    description:
      "dmytro_avramenko_story",
  },
  {
    name: "ruslan_tron",
    position: "Front End Developer",
    image: "/success-story/tron.png",
    description:
      "ruslan_tron_story",
  },
]
