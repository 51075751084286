import * as React from "react"
import * as css from "./success-stories-list.module.scss"
import { SuccessStoryScreen } from "./success-story-screen/success-story-screen"
import { SuccessStoriesInfoList } from "./success-stories-info-list"

export const SuccessStoriesList = () => {
  return (
    <div className={css.list}>
      {SuccessStoriesInfoList.map((story, index) => (
        <SuccessStoryScreen
          key={index}
          story={story}
          reverse={index % 2 !== 0}
        />
      ))}
    </div>
  )
}
