import "./../../style/common.scss"
import { CommonLayout } from "../../common/common-layout/common-layout"
import * as React from "react"
import { SuccessStoriesPage } from "../../page-components/career/success-stories/success-stories-page"

export default function SuccessStories(props) {
  return (
    <CommonLayout {...props}>
      <SuccessStoriesPage/>
    </CommonLayout>
  )
}
